/*

	cents.ts

	Add cents to cost value

	Authors:	Ilia Ashmarin (mail[0]harduino.com)

	History:	2018.09.05 - File created
*/
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'cents',
})
export class CentsPipe implements PipeTransform {
	transform( value, changeIndicator:number ) : any {
		return parseFloat(value).toFixed(2);
	}
}
