/*

	user.ts

	Provider for user

	Authors:	Ilia Ashmarin (mail[0]harduino.com)

	History:	2018.04.12 - File created

*/
import {Injectable} from '@angular/core';
import {Subject, BehaviorSubject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {Platform} from '@ionic/angular';
import {Device} from '@ionic-native/device/ngx';

import {CfgProvider} from './cfg';
import {SettingsProvider} from './settings';
import {HelpersProvider} from './helpers';
import {LogProvider} from './log';
import {ToastProvider} from './toast';
import {ApiProvider} from './api';

@Injectable()
export class UserProvider {

    lastAuthState = null;

    settingsData = {userState: null};
    authState$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    info = {
        common: {},
        user: {},
        shop: {},
        courierData: {},
        restData: {}
    };
    infoMap = {
        courierInfo: 'user',
        courierData: 'courierData',
        restInfo: 'shop',
        restData: 'restData'
    };
    info$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    rights;
    restaurant;

    constructor(
        public platform: Platform,
        public device: Device,
        public cfg: CfgProvider,
        public log: LogProvider,
        public settings: SettingsProvider,
        public helpers: HelpersProvider,
        public toast: ToastProvider,
        public api: ApiProvider
    ) {
        this.api.user = this;
    }

    // initialise provider
    ngUnsubscribe: Subject<void> = new Subject<void>();

    async init() {
        // subscribing for getting actual settings list
        this.settings.data$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((settings) => {
                this.settingsData = settings;
                this.formActualInfo();
            })
        ;
    }

    // return user's auth state
    isAuthorized() {
        return this.settingsData['xAuthHeader'] != '' ? true : false;
    }

    // form actual user info based on local settings
    async formActualInfo() {
        // extracting user info
        for (let settingName in this.infoMap) {
            let infoIndex = this.infoMap[settingName];
            let jsonData = this.settingsData[settingName];

            // form actual value
            let infoData = {};
            if (jsonData) {
                if (typeof jsonData == 'string') {
                    try {
                        infoData = JSON.parse(jsonData);
                    } catch (e) {
                        this.log.debug(`Failed to parse setting ${settingName}: ` + e.toString());
                    }
                } else {
                    infoData = jsonData;
                }
            }
            this.info[infoIndex] = infoData;
        }

        // form info-common row
        this.info.common = {};
        if (!this.helpers.isEmpty(this.info.user)) {
            this.info.common = this.info.user;
            this.info.common['type'] = 'courier';
        }
        if (!this.helpers.isEmpty(this.info.shop)) {
            this.restaurant.prepareRestaurant(this.info.restData);
            this.info.common = this.info.shop;
            this.info.common['type'] = 'restaurant';
        }

        // sending to subscribers actual user info
        this.info$.next(this.info);

        // check for triggering changing auth state
        let currentAuthState = this.isAuthorized();
        if (
            (
                // auth state was not setted
                this.lastAuthState === null ||

                // OR last auth state was changed
                this.lastAuthState != currentAuthState
            )
            &&

            (
                // we are unauthorized
                !currentAuthState ||

                // OR user's info was formed
                !this.helpers.isEmpty(this.info.common)
            )
        ) {
            this.lastAuthState = currentAuthState;
            this.authState$.next({
                state: this.lastAuthState,
                info: this.info
            });
        }
    }

    // get actual user info based on type
    async updateActualInfo() {
        if (this.rights.isCourier()) {
            await this.updateActualCourierInfo();
        }
        if (this.rights.isRestaurant()) {
            await this.updateActualRestaurantInfo();
        }
    }

    // get user actual info from API
    async updateActualCourierInfo() {
        await this.updateInfo('couriers/getCourierInfo', 'courierInfo', 'courierData', ['restInfo', 'restData']);
    }

    // get user actual info from API
    async updateActualRestaurantInfo() {
        await this.updateInfo('restaurant/getRestaurantInfo', 'restInfo', 'restData', ['courierInfo', 'courierData']);
    }

    async updateInfo(apiMethod, settingNameCommon, settingName, settingsNameEmpty) {
        try {
            // send requests to api in parallel
            let [userCommonInfo, userInfo] = [await this.api.post('account/user/info'), await this.api.post(apiMethod)];

            // process API-server's response
            if (userCommonInfo['status'] != 'success') {
                throw new Error(userCommonInfo['message']);
            }
            if (!userCommonInfo['result']) {
                throw new Error('Server returned empty common user info!');
            }
            if (userInfo['status'] != 'success') {
                throw new Error(userInfo['message']);
            }
            if (!userInfo['result']) {
                throw new Error('Server returned empty user info!');
            }

            // form new settings values
            let settings = {};
            settings[settingNameCommon] = JSON.stringify(userCommonInfo['result']);
            settings[settingName] = JSON.stringify(userInfo['result']);
            for (let i = 0; i < settingsNameEmpty.length; i++) {
                settings[settingsNameEmpty[i]] = '';
            }

            // save actual user's info in local storage
            await this.settings.SetMulti(settings);
            await this.formActualInfo();
        } catch (e) {
            let msg = `Failed to actualize user settings: ` + e.toString();
            this.log.debug(msg);
            this.toast.alert(msg);
        }
    }

    // get user's device token
    getDeviceToken() {
        return this.device.uuid;
    }

    // get user's device type
    getDeviceType() {
        let result = 'web';
        let map = {
            android: 'android',
            ios: 'ios',
            windows: 'windows',
            web: 'mobileweb'
        };
        for (let deviceType in map) {
            let platformCheckName = map[deviceType];
            if (this.platform.is(platformCheckName)) {
                result = deviceType;
                break;
            }
        }

        return result;
    }

    // get user's device platforms
    getPlatforms() {
        return this.platform.platforms();
    }

    async logout() {
        await this.api.post('account/logout', {
            device: this.getDeviceToken()
        });
        await this.settings.SetMulti({
            xAuthHeader:	'',
            restInfo:		'',
            courierData: '',
            restData: '',
            courierInfo:	''
        });
    }

    async delete() {
        const res = await this.api.delete('account');
        if (res.status !== 'success') {
            throw res;
        }

        return res;
    }
}
