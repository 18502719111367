import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { IonicStorageModule } from '@ionic/storage';
import { Device } from '@ionic-native/device/ngx';
import { CallNumber } from '@ionic-native/call-number/ngx';

import { HttpClientModule } from '@angular/common/http';

import { CfgProvider } from './providers/cfg';
import { ApiProvider } from './providers/api';
import { LogProvider } from './providers/log';
import { SettingsProvider } from './providers/settings';
import { UserProvider } from './providers/user';
import { RedirectionProvider } from './providers/redirection';
import { ToastProvider } from './providers/toast';
import { RightsProvider } from './providers/rights';
import { HelpersProvider } from './providers/helpers';
import { CityProvider } from './providers/city';
import { RestaurantProvider } from './providers/restaurant';
import { CourierProvider } from './providers/courier';
import { PushProvider } from './providers/push';
import { PipesModule } from './pipes/pipes.module';
import { TosProvider } from './providers/tos';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TabsPage } from './tabs/tabs.page';
import { OrderInfoPage } from './order-info/order-info.page';
import { RestaurantQuartersPage } from './restaurant-quarters/restaurant-quarters.page';
import { FormsModule } from '@angular/forms';
import { AuthGuard } from './auth.guard';

@NgModule({
  declarations: [
    AppComponent,
    TabsPage,
    OrderInfoPage,
    RestaurantQuartersPage,
  ],
  entryComponents: [
    OrderInfoPage,
    RestaurantQuartersPage
  ],
  imports: [
    PipesModule,
    BrowserModule,
    HttpClientModule,
    IonicModule.forRoot(),
    IonicStorageModule.forRoot(),
    AppRoutingModule,
    FormsModule,
  ],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    CfgProvider,
    ApiProvider,
    LogProvider,
    SettingsProvider,
    UserProvider,
    RedirectionProvider,
    ToastProvider,
    RightsProvider,
    HelpersProvider,
    CityProvider,
    RestaurantProvider,
    CourierProvider,
    PushProvider,
    Device,
    CallNumber,
    TosProvider,
    AuthGuard,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
