import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

//PIPE

import { KeysPipe } from './keys.pipe';
import { RestaurantPreparationTimePipe } from './restaurant-preparation-time.pipe';
import { ReversePipe } from './reverse.pipe';
import { SortQuartersPipe } from './sort-quarters.pipe';
import { ValuesPipe } from './values.pipe';
import { Nl2BrPipeModule } from 'nl2br-pipe';
import { CentsPipe } from './cents.pipe';


@NgModule({
  imports: [
    CommonModule,
    Nl2BrPipeModule
  ],
  declarations: [
    KeysPipe,
    ValuesPipe,
    CentsPipe,
    SortQuartersPipe,
    RestaurantPreparationTimePipe,
    ReversePipe
  ],
  exports: [
    CommonModule,
    Nl2BrPipeModule,
    KeysPipe,
    CentsPipe,
    ValuesPipe,
    SortQuartersPipe,
    RestaurantPreparationTimePipe,
    ReversePipe
  ],
  providers:[
    KeysPipe,
    ValuesPipe,
    SortQuartersPipe,
    RestaurantPreparationTimePipe,
    ReversePipe
  ]
})
export class PipesModule { }
