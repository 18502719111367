/*

	restaurant.ts

	Provider to work with restaurant

	Authors:	Ilia Ashmarin (mail[0]harduino.com),
				Matteo Carrara (team@flaaash.it)

	History:	2018.06.26 - File created

*/
import {Injectable} from '@angular/core';

import {Subject, BehaviorSubject, throwError, of} from 'rxjs';
import {map, skipWhile, switchMap, take, takeUntil} from 'rxjs/operators';

import {CfgProvider} from './cfg';
import {HelpersProvider} from './helpers';
import {ToastProvider} from './toast';
import {ApiProvider} from './api';
import {UserProvider} from './user';

@Injectable()
export class RestaurantProvider {
    info = null;
    info$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    ngUnsubscribe: Subject<void> = new Subject<void>();
    openHours = null;
    openHours$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    availableQuarters = null;
    availableQuarters$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    secondaryProducts = null;
    secondaryProducts$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    menu = null;
    menu$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    _secondaryProductsCategories$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    additionalProducts$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    constructor(
        public cfg: CfgProvider,
        public helpers: HelpersProvider,
        public toast: ToastProvider,
        public api: ApiProvider,
        public user: UserProvider
    ) {
        this.user.restaurant = this;
    }

    async init() {
        this.user.info$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((info) => {
                if (info && info.shop && !this.helpers.isEmpty(info.shop)) {
                    this.info = info.shop;
                    this.info$.next(this.info);
                }
            });
    }

    get secondaryProductsCategories$() {
        return this._secondaryProductsCategories$.pipe(
            skipWhile(categories => !categories),
        );
    }

    getSecondaryProductsCategories(filters = null) {
        const queryParams = new URLSearchParams();
        if (filters) {
            queryParams.set('filters', JSON.stringify(filters));
        }

        return this.api.query('get', `restaurant/secondaryProductsCategory?filters=${queryParams.toString()}`)
            .pipe(
                switchMap(response => {
                    if (response.status !== 'success') {
                        return throwError(response);
                    }
                    return of(response);
                }),
                map(response => {
                    const result = response.result || [];
                    return result;
                }),
                take(1)
            ).toPromise();
    }

    async actualizeSecondaryProductsCategories() {
        const categories = await this.getSecondaryProductsCategories();
        this._secondaryProductsCategories$.next(categories);
        return categories;
    }

    async getProductSecondaryCategoriesOrder(productId) {
        return this.api.query('get', `product/${productId}/secondaryProductsCategories/order`)
            .pipe(
                switchMap(response => {
                    if (response.status !== 'success') {
                        return throwError(response);
                    }
                    return of(response);
                }),
                map(response => {
                    const result = response.result || [];
                    return result;
                }),
                take(1)
            ).toPromise();
    }

    getAdditionalProducts(filters = null) {
        const queryParams = new URLSearchParams();
        if (filters) {
            queryParams.set('filters', JSON.stringify(filters));
        }

        return this.api.query('get', `restaurant/additional-products?filters=${queryParams.toString()}`)
            .pipe(
                switchMap(response => {
                    if (response.status !== 'success') {
                        return throwError(response);
                    }
                    return of(response);
                }),
                map(response => {
                    const result = response.result || [];
                    return result;
                }),
                take(1)
            ).toPromise();
    }

    async actualizeAdditionalProducts() {
        const additionalProducts = await this.getAdditionalProducts();
        this.additionalProducts$.next(additionalProducts);
        return additionalProducts;
    }

    async actualizeOpenHours() {
        await this.api.actualize.call(
            this,
            {
                method: 'restaurant/hours/get',
                varName: 'openHours'
            }
        );
    }

    async updateOpenHours(newData) {
        await this.api.updateData.call(
            this,
            {
                method: 'restaurant/hours/set',
                data: newData,
                varName: 'openHours'
            }
        );
    }

    async actualizeAvailableQuarters() {
        await this.api.actualize.call(
            this,
            {
                method: 'restaurant/quarters/get',
                varName: 'availableQuarters'
            }
        );
    }

    async updateAvailableQuarters(newData) {
        await this.api.updateData.call(
            this,
            {
                method: 'restaurant/quarters/set',
                data: newData,
                varName: 'availableQuarters'
            }
        );
    }

    async actualizeSecondaryProducts() {
        await this.api.actualize.call(
            this,
            {
                method: 'restaurant/product/getSecondaryProducts',
                varName: 'secondaryProducts'
            }
        );
    }

    async actualizeMenu() {
        await this.api.actualize.call(
            this,
            {
                methodType: 'get',
                method: 'restaurant/getRestaurantMenu',
                varName: 'menu',
                sendSubscribers: false
            }
        );

        // process products
        for (let i = 0; i < this.menu.length; i++) {
            let menuItem = this.menu[i];
            for (let j = 0; j < menuItem.products.length; j++) {
                let productItem = menuItem.products[j];
                productItem = this.prepareProduct(productItem);
            }
        }

        // send to subscribers
        this.menu$.next(this.menu);
    }

    async productDelete(productId) {
        try {
            this.cfg.loading(true);
            let responseData = await this.api.post(
                'restaurant/deleteProduct',
                {
                    productId: productId
                }
            );

            // process response from API-server
            if (responseData['status'] == 'success') {
                this.actualizeMenu();
            } else {
                throw new Error(responseData.message);
            }
        } catch (e) {
            console.warn(e);
            throw e;
        } finally {
            this.cfg.loading(false);
        }
    }

    async secondaryProductDelete(secondaryProductId) {
        try {
            await this.cfg.loading(true);
            const responseData = await this.api.post(
                'restaurant/product/deleteSecondaryProduct',
                { secondaryProductId }
            );

            // process response from API-server
            if (responseData.status === 'success') {
                await this.actualizeSecondaryProducts();
                await this.actualizeSecondaryProductsCategories();
            } else {
                throw new Error(responseData.message);
            }
        } catch (e) {
            console.warn(e);
            throw e;
        } finally {
            await this.cfg.loading(false);
        }
    }

    async additionalProductDelete(id) {
        try {
            await this.cfg.loading(true);
            const responseData = await this.api.delete(`restaurant/additional-products/${id}`);

            // process response from API-server
            if (responseData.status === 'success') {
                await this.actualizeAdditionalProducts();
            } else {
                throw new Error(responseData.message);
            }
        } catch (e) {
            console.warn(e);
            throw e;
        } finally {
            await this.cfg.loading(false);
        }
    }

    prepareRestaurant(restaurant) {
        if (!restaurant.restaurantLogo || restaurant.restaurantLogo.substr(0, 4) != 'http') {
            if (restaurant.restaurantLogo) {
                restaurant.restaurantLogo = this.cfg.url.api + restaurant.restaurantLogo;
            } else {
                restaurant.restaurantLogo = this.cfg.url.placeholder.productImage;
            }
        }

        if (!restaurant.restaurantCover || restaurant.restaurantCover.substr(0, 4) != 'http') {
            if (restaurant.restaurantCover) {
                restaurant.restaurantCover = this.cfg.url.api + restaurant.restaurantCover;
            } else {
                restaurant.restaurantCover = this.cfg.url.placeholder.restaurantCover;
            }
        }

        return restaurant;
    }

    prepareProduct(product) {
        // form product's image
        if (!product.image || product.image.substr(0, 4) != 'http') {
            if (product.image) {
                product.image = this.cfg.url.api + product.image;
            } else {
                product.image = this.cfg.url.placeholder.productImage;
            }
        }

        return product;
    }
}
