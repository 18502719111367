/*

	redirection.ts

	Provider for work with redirection

	Authors:	Matteo Carrara (team@flaaash.it)

	History:	2018.04.13 - File created

*/
//
import { Injectable } from '@angular/core';
import { NavController } from '@ionic/angular';

@Injectable()
export class RedirectionProvider {
    data: any;

    constructor(public navCtrl: NavController) {
        // ...
    }

    push(url: string, data: any = '') {
        this.data = data;

        this.navCtrl.navigateForward('/' + url);
    }

    pop(url) {
        this.navCtrl.navigateBack('/' + url);
    }

    back() {
        this.navCtrl.back();
    }

    get(key: string) {
        return this.data[key];
    }

    setRoot(url: string, data: any = '') {
        this.data = data;

        this.navCtrl.navigateRoot('/' + url);
    }
}
