import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { CfgProvider } from '../providers/cfg';
import { HelpersProvider } from '../providers/helpers';
import { RedirectionProvider } from '../providers/redirection';
import { ToastProvider } from '../providers/toast';
import { RestaurantProvider } from '../providers/restaurant';
import { SettingsProvider } from '../providers/settings';

@Component({
  selector: 'app-restaurant-quarters',
  templateUrl: './restaurant-quarters.page.html',
  styleUrls: ['./restaurant-quarters.page.scss'],
})
export class RestaurantQuartersPage {

  settingsData;

  quarters = {};
  selectedQuarters = {};

  constructor(
    public cfg:CfgProvider,
		public helpers:HelpersProvider,
		public redirection:RedirectionProvider,
		public toast:ToastProvider,
		public restaurant:RestaurantProvider,
		public settings:SettingsProvider,
    public modal: ModalController
  ) { }

  ngUnsubscribe: Subject<void> = new Subject<void>();
  async ionViewWillEnter(){
    // subscribe for getting actual settings
		this.settings.data$
			.pipe(takeUntil( this.ngUnsubscribe ))
			.subscribe( settingsData => {
				if( settingsData && settingsData['server'] ) {
					this.settingsData = settingsData;

					this.drawQuarters();
				}
			})
		;

		// subscribing for getting actual restaurant's available quarters
		this.restaurant.availableQuarters$
			.pipe(takeUntil( this.ngUnsubscribe ))
			.subscribe( (availableQuarters) => {

				if( this.helpers.isFine(availableQuarters) ) {
					// compute enabled days
					this.selectedQuarters = {};
					for( let i = 0;i < availableQuarters.length;i++ ) {
						this.selectedQuarters[ availableQuarters[i] ] = true;
					}
				}

				if( this.refresher ) {
					this.refresher.target.complete();
				}
			})
		;

		// calling method to get actual restaurant's open hours
		this.doRefresh();
  }
  ionViewWillLeave() {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}

	// draw quarters based on list, received from server
	drawQuarters() {
		this.quarters = this.helpers.groupQuarters( this.settingsData['server']['quarterTime'] );
	}

	// quarter checked/unchecked
	updateSelection( quarter ) {
		this.selectedQuarters[quarter] = !this.selectedQuarters[quarter];
	}

	// select all quarters as available
	all() {
		this.selectedQuarters = {};
		for( let i = 0;i <  this.settingsData['server']['quarterTime'].length;i++ ) {
			let quarter = this.settingsData['server']['quarterTime'][i];
			this.selectedQuarters[ quarter ] = true;
		}
	}

	// clear all available quarters
	clear() {
		this.selectedQuarters = {};
	}

  saveLoader = false;
	// save selected available quarters on server
	async save() {
    this.saveLoader = true;
		// form data to be sended
		let data = new Array();
		for( let quarter in this.selectedQuarters ) {
			if( this.selectedQuarters[quarter] ) {
				data.push( quarter );
			}
		}

		// send to server
		try {
			await this.restaurant.updateAvailableQuarters(data);
			this.toast.create('Slot aggiornati con successo!');
      this.modal.dismiss();
		} catch(e) {
			this.toast.alert(e.message);
		}
    this.saveLoader = false;
	}

	// refresh current
	refresher;
	doRefresh(refresher=null) {
		this.refresher = refresher;
		this.restaurant.actualizeAvailableQuarters();
	}
}
