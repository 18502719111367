/*

	values.ts

	Get object's values

	Authors:	Ilia Ashmarin (mail[0]harduino.com)

	History:	2018.06.27 - File created
*/
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'values',
})
export class ValuesPipe implements PipeTransform {
	transform(value, changeIndicator: number): any {
		if (typeof value !== 'object') {
			return [];
		} else {
			let res = new Array();
			for (let index in value) {
				res.push(value[index]);
			}
			return res;
		}
	}
}
