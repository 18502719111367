/*

	rights.ts

	Work with user rights

	Authors:	Ilia Ashmarin (mail[0]harduino.com)

	History:	2018.06.26 - File created

*/
import {Injectable} from '@angular/core';
import {Subject, BehaviorSubject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {CfgProvider} from './cfg';
import {HelpersProvider} from './helpers';
import {UserProvider} from './user';

@Injectable()
export class RightsProvider {

    info = null;

    ngUnsubscribe: Subject<void> = new Subject<void>();

    constructor(
        public cfg: CfgProvider,
        public helpers: HelpersProvider,
        public user: UserProvider
    ) {
        this.user.rights = this;

        // subscribing for getting actual user info
        this.user.info$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((info) => {
                if (info !== null && !helpers.isEmpty(info.common)) {
                    this.info = info.common;
                    this.actualizeIam();
                }
            })
        ;
    }

    Iam;
    Iam$: BehaviorSubject<any> = new BehaviorSubject<any>({});

    actualizeIam() {
        this.Iam = {
            restaurant: this.isRestaurant(),
            courier: this.isCourier(),
            restaurantOwner: this.info.userType === this.cfg.userType.USER_TYPE_RESTAURANT_OWNER,
        };
        this.Iam$.next(this.Iam);
    }

    isRestaurant(typeIndex = null) {
        if (typeIndex === null) {
            if (this.info) {
                return [
                    this.cfg.userType.USER_TYPE_RESTAURANT_OWNER,
                    this.cfg.userType.USER_TYPE_RESTAURANT_MANAGER
                ].includes(this.info.userType);
            } else {
                return false;
            }
        } else {
            typeIndex = typeIndex['userType'] || typeIndex;
            return [
                this.cfg.userType.USER_TYPE_RESTAURANT_OWNER,
                this.cfg.userType.USER_TYPE_RESTAURANT_MANAGER
            ].includes(typeIndex);
        }
    }

    isCourier(typeIndex = null) {
        if (typeIndex == null) {
            if (this.info) {
                return this.info.userType === this.cfg.userType.USER_TYPE_COURIER;
            } else {
                return false;
            }
        } else {
            typeIndex = typeIndex['userType'] || typeIndex;
            return typeIndex == this.cfg.userType.courier;
        }
    }
}
