import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';

/*
  Generated class for the FcmProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable()
export class PushProvider {

    public oneSignalAppId: string = "17ac890d-bbea-4822-80cc-d8545c88a818";
    public oneSignalRestApiKey: string = "NzRlZjRlYzAtZTRhOS00MjkwLThhMzMtYmI0MzEyZTkwNTMx";
    public senderId: string = "758858649078";
    public playerID: any;

    constructor(public http: HttpClient, public platform: Platform) {

    }

    setupOneSignal() {
        if (!this.platform.is('mobileweb') && (this.platform.is('ios') || this.platform.is('android'))) { }
        else {
            return;
        }
        var tt = this;

        var iosSettings = {};
        iosSettings["kOSSettingsKeyAutoPrompt"] = true; // will prompt users when start app 1st time
        iosSettings["kOSSettingsKeyInAppLaunchURL"] = false; // false opens safari with Launch URL

        var notificationOpenedCallback = function (jsonData) {
            console.log("=================== open push ======", jsonData);
            let data = jsonData.notification.payload.additionalData;

        };
        var notificationReceivedCallback = function (jsonData) {
            console.log(jsonData);
            let data = jsonData.payload.additionalData;
            if (jsonData.isAppInFocus) {

            } else {

            }
        }

        var addSubscription = function (state) {
            tt.getOneSignalPlayerId();
        }

        window["plugins"].OneSignal
            .startInit(this.oneSignalAppId, this.senderId)
            .iOSSettings(iosSettings) // only needed if added Optional OneSignal code for iOS above
            .inFocusDisplaying(0)
            .handleNotificationOpened(notificationOpenedCallback)
            .handleNotificationReceived(notificationReceivedCallback)
            .endInit();

        this.getOneSignalPlayerId();
        window["plugins"].OneSignal.addSubscriptionObserver(addSubscription);
    }

    getOneSignalPlayerId(callback = null) {
        if (!this.platform.is('mobileweb') && (this.platform.is('ios') || this.platform.is('android'))) {
            var tt = this;
            window["plugins"].OneSignal.getPermissionSubscriptionState(function (status) {
                status.permissionStatus.hasPrompted;
                status.permissionStatus.status;

                status.subscriptionStatus.subscribed;
                status.subscriptionStatus.userSubscriptionSetting;
                status.subscriptionStatus.pushToken;

                tt.playerID = status.subscriptionStatus.userId;
                tt.registerPlayerId();

                if (callback != null) callback();
            });
        }
    }

    sendNotificationWeb(text, playerId) {
        let headerJson = {
            'Content-Type': 'application/json; charset=utf-8',
            'Accept': 'application/json',
            'Authorization': "Basic " + this.oneSignalRestApiKey
        };
        let headers = new HttpHeaders(headerJson);
        let url = "https://onesignal.com/api/v1/notifications";

        let badgeCount = 1;
        let badgeType = "Increase";
        var data = {
            app_id: this.oneSignalAppId,
            contents: { "en": text },
            include_player_ids: [playerId],
            ios_badgeType: badgeType,
            ios_badgeCount: badgeCount,
            content_available: true
        };
        let body = JSON.stringify(data);
        this.http.post(url, body, { headers: headers }).subscribe(data => {
            console.log("Success: ", data);
        }, err => {
            console.log("Error: send notification");
        })
    }

    registerPlayerId() {

    }

}
