import { SettingsProvider } from './providers/settings';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';


@Injectable()
export class AuthGuard implements CanActivate {

  constructor(
      private settings: SettingsProvider,
      private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    if (this.settings.authorized()) {
        return true;
    } else {
      this.router.navigate(['/welcome-slides']);
      return false;
    }
  }
}