/*

	log.ts

	Provider to work with logging

	Authors:	Matteo Carrara (team@flaaash.it)

	History:	2018.05.04 - File created

*/
import { Injectable } from '@angular/core';

import { CfgProvider } from './cfg';
import { HelpersProvider } from './helpers';

@Injectable()
export class LogProvider {

	constructor(
		public cfg:CfgProvider,
		public helpers:HelpersProvider
	) { }

	debug( ...params ) {
		if( this.cfg.debug ) {
			let args = [].slice.call(arguments);
			args.unshift( '[DEBUG]' );
			this.add.apply( this, args );
		}
	}

	add( ...params ) {
		// android's logcat printing only first argument
		if( this.helpers.isBrowser() ) {
			console.log.apply( console, arguments );
		} else {
			let args = [].slice.call(arguments);
			for( let i = 0;i < args.length;i++ ) {
				let arg = args[i];
				let type = typeof arg;
				if( type == 'object' ) {
					args[i] = JSON.stringify(arg);
				}
			}
			console.log( args.join(', ') );
		}
	}
}
