/*

	restaurant-preparation-time.ts

	Format delivery quarter for restaurant - substract 10 minutes

	Authors:	Ilia Ashmarin (mail[0]harduino.com)

	History:	2018.11.13 - File created
*/
import { Pipe, PipeTransform } from '@angular/core';
import { HelpersProvider } from '../providers/helpers';

@Pipe({
	name: 'restaurantPreparationTime',
})
export class RestaurantPreparationTimePipe implements PipeTransform {
	constructor(public helpers:HelpersProvider) {};
	transform(value, changeIndicator:number) : any {
		return this.helpers.getRestaurantPreparationTime(value);
	}
}
