import {Component, ViewChild} from '@angular/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {AlertController, IonContent, PopoverController, NavParams, ModalController} from '@ionic/angular';

import {CallNumber} from '@ionic-native/call-number/ngx';
// import { LaunchNavigator, LaunchNavigatorOptions } from '@ionic-native/launch-navigator';

import {CfgProvider} from '../providers/cfg';
import {ApiProvider} from '../providers/api';
import {LogProvider} from '../providers/log';
import {SettingsProvider} from '../providers/settings';
import {RedirectionProvider} from '../providers/redirection';
import {ToastProvider} from '../providers/toast';
import {RightsProvider} from '../providers/rights';

@Component({
    selector: 'app-order-info',
    templateUrl: './order-info.page.html',
    styleUrls: ['./order-info.page.scss'],
})
export class OrderInfoPage {

    @ViewChild(IonContent, {static: false}) content: IonContent;

    orderData;
    settingsData;
    Iam;

    constructor(
        public alertCtrl: AlertController,
        public navParams: NavParams,
        public cfg: CfgProvider,
        public log: LogProvider,
        public api: ApiProvider,
        public redirection: RedirectionProvider,
        public settings: SettingsProvider,
        public rights: RightsProvider,
        public toast: ToastProvider,
        public callNumber: CallNumber,
        public modalController: ModalController
    ) {
        let order = navParams.get('order');
        if (order) {
            this.orderData = order;
            console.log('this.orderData', this.orderData);
        } else {
            this.toast.alert({
                header: 'Errore',
                message: 'Order\'s info not found',
                buttons: ['Ok']
            });
            this.redirection.pop('/tabs/ordini');
        }
    }

    ngUnsubscribe: Subject<void> = new Subject<void>();

    async ionViewWillEnter() {
        // subscribe for getting actual settings list
        this.settings.data$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(settingsData => this.onSettings(settingsData))
        ;

        // subscribe for getting actual "I am" state
        this.rights.Iam$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(Iam => this.onRights(Iam))
        ;
    }

    ionViewWillLeave() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    // handler for actual settings
    onSettings(settingsData) {
        this.settingsData = settingsData;
        this.prepare();
    }

    // handler for actual rights
    onRights(Iam) {
        this.Iam = Iam;
        setTimeout(() => {
            if (this.content) {
                // this.content.resize();
            }
        }, 0);
        this.prepare();
    }

    // handler when required data was loaded
    prepare() {
        if (this.settingsData && this.Iam) {
            // if order is new - set is as seen (for restaurant only)
            if (this.Iam.restaurant && this.orderData.orderState <= this.settingsData.server.const.ORDER_STATE_ACCEPTED) {
                this.setOrderAsSeen();
            }
        }
    }

    // set order's state to "seen"
    async setOrderAsSeen() {
        await this.cfg.loading(true);
        try {
            let data = await this.api.post('restaurant/setOrderAsSeen', {
                orderId: this.orderData._id
            });

            if (data.status == 'success') {
                this.orderData.orderState = this.settingsData.server.const.ORDER_STATE_SEEN;
            } else {
                throw new Error(data.message);
            }
        } catch (e) {
            this.log.debug(e.message || e.error);
            this.toast.alert({
                header: 'Error',
                message: 'Failed to update order\'s state: ' + e.message,
                buttons: ['Ok']
            });
        }
        await this.cfg.loading(false);
    }

    // set order's state to "under preparation"
    async setAsPreparation() {
        await this.cfg.loading(true);
        try {
            let data = await this.api.post('restaurant/underPreparation', {
                orderId: this.orderData._id
            });

            if (data.status == 'success') {
                this.orderData.orderState = this.settingsData.server.const.ORDER_STATE_UNDERPREPARATION;
            } else {
                throw new Error(data.message);
            }
        } catch (e) {
            this.log.debug(e.message || e.error);
            this.toast.alert({
                header: 'Error',
                message: 'Failed to update order\'s state: ' + e.message,
                buttons: ['Ok']
            });
        }
        await this.cfg.loading(false);
    }

    // set order's state to "on delivery"
    async setAsDelivery() {
        await this.cfg.loading(true);
        try {
            let data = await this.api.post('couriers/setOrderAsOnDelivery', {
                orderId: this.orderData._id
            });

            if (data.status == 'success') {
                this.orderData.orderState = this.settingsData.server.const.ORDER_STATE_ONDELIVERY;
            } else {
                throw new Error(data.message);
            }
        } catch (e) {
            this.log.debug(e.message || e.error);
            this.toast.alert({
                header: 'Error',
                message: 'Failed to update order\'s state: ' + e.message,
                buttons: ['Ok']
            });
        }
        await this.cfg.loading(false);
    }

    // set order's state to "completed"
    async setAsCompleted() {
        await this.cfg.loading(true);
        try {
            let data = await this.api.post('couriers/setOrderAsCompleted', {
                orderId: this.orderData._id
            });

            if (data.status == 'success') {
                this.orderData.orderState = this.settingsData.server.const.ORDER_STATE_COMPLETED;
            } else {
                throw new Error(data.message);
            }
        } catch (e) {
            this.log.debug(e.message || e.error);
            this.toast.alert({
                header: 'Error',
                message: 'Failed to update order\'s state: ' + e.message,
                buttons: ['Ok']
            });
        }
        await this.cfg.loading(false);
    }

    async call(customerContact) {
        await this.callNumber.callNumber(customerContact, false);
    }

    gotoOrderRefund(order) {
        this.modalController.dismiss();
        this.redirection.push(`/order/${order._id}/refund`, {order});
    }
}
