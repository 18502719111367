import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { TabsPage } from './tabs/tabs.page';

const routes: Routes = [
  // {
  //   path: '',
  //   pathMatch: 'full',
  //   redirectTo: 'initial'
  // },
  {
    path: 'tabs',
    component: TabsPage,
    children: [
      {
        path: 'ordini',
        loadChildren: () => import('./ordini/ordini.module').then(m => m.OrdiniPageModule)
      },
      {
        path: 'impostazioni',
        loadChildren: () => import('./impostazioni/impostazioni.module').then(m => m.ImpostazioniPageModule)
      },
      {
        path: '',
        redirectTo: '/tabs/ordini',
        pathMatch: 'full'
      }
    ]
  },
  {
    path: 'confirm-user-invitation',
    loadChildren: () => import('./confirm-user-invitation/confirm-user-invitation.module').then( m => m.ConfirmUserInvitationModule)
  },
  {
    path: 'account-personal-info',
    loadChildren: () => import('./account-personal-info/account-personal-info.module').then( m => m.AccountPersonalInfoPageModule)
  },
  {
    path: 'restaurant-invitation',
    loadChildren: () => import('./restaurant-invitation/restaurant-invitation.module').then( m => m.RestaurantInvitationModule),
  },
  {
    path: 'credential-login-restore',
    loadChildren: () => import('./credential-login-restore/credential-login-restore.module').then( m => m.CredentialLoginRestorePageModule)
  },
  {
    path: 'credential-login-restore-confirm',
    loadChildren: () => import('./credential-login-restore-confirm/credential-login-restore-confirm.module').then( m => m.CredentialLoginRestoreConfirmPageModule)
  },
  {
    path: 'daily-history',
    loadChildren: () => import('./daily-history/daily-history.module').then( m => m.DailyHistoryPageModule)
  },
  {
    path: 'goto-root',
    loadChildren: () => import('./goto-root/goto-root.module').then( m => m.GotoRootPageModule)
  },
  {
    path: 'initial',
    loadChildren: () => import('./initial/initial.module').then( m => m.InitialPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'logout',
    loadChildren: () => import('./logout/logout.module').then( m => m.LogoutPageModule)
  },
  {
    path: 'manage-menu-category',
    loadChildren: () => import('./manage-menu-category/manage-menu-category.module').then( m => m.ManageMenuCategoryPageModule)
  },
  {
    path: 'manage-menu-category-order',
    loadChildren: () => import('./manage-menu-category-order/manage-menu-category-order.module')
        .then( m => m.ManageMenuCategoryOrderPageModule)
  },
  {
    path: 'manage-menu-items-order',
    loadChildren: () => import('./manage-menu-items-order/manage-menu-items-order.module')
        .then( m => m.ManageMenuItemsOrderModule)
  },
  {
    path: 'manage-product',
    loadChildren: () => import('./manage-product/manage-product.module').then( m => m.ManageProductPageModule)
  },
  {
    path: 'manage-secondary-product-item',
    loadChildren: () => import('./manage-secondary-product-item/manage-secondary-product-item.module')
        .then( m => m.ManageSecondaryProductItemPageModule)
  },
  {
    path: 'manage-secondary-product',
    loadChildren: () => import('./manage-secondary-product/manage-secondary-product.module').then( m => m.ManageSecondaryProductPageModule)
  },
  {
    path: 'menu-items',
    loadChildren: () => import('./menu-items/menu-items.module').then( m => m.MenuItemsPageModule)
  },
  {
    path: 'manage-secondary-products-category',
    loadChildren: () => import('./manage-secondary-products-category/manage-secondary-products-category.module')
        .then( m => m.ManageMenuCategoryPageModule)
  },
  {
    path: 'manage-secondary-products-category-order',
    loadChildren: () => import('./manage-secondary-products-category-order/manage-secondary-products-category-order.module')
        .then( m => m.ManageSecondaryProductsCategoryOrderModule)
  },
  {
    path: 'manage-additional-product',
    loadChildren: () => import('./manage-additional-product/manage-additional-product.module').then( m => m.ManageAdditionalProductPageModule)
  },
  {
    path: 'manage-additional-product-item',
    loadChildren: () => import('./manage-additional-product-item/manage-additional-product-item.module')
        .then( m => m.ManageAdditionalProductItemPageModule)
  },
  {
    path: 'open-hours',
    loadChildren: () => import('./open-hours/open-hours.module').then( m => m.OpenHoursPageModule)
  },
  {
    path: 'order-history',
    loadChildren: () => import('./order-history/order-history.module').then( m => m.OrderHistoryPageModule)
  },
  {
    path: 'order-info',
    loadChildren: () => import('./order-info/order-info.module').then( m => m.OrderInfoPageModule)
  },
  {
    path: 'order/:id/refund',
    loadChildren: () => import('./order-refund/order-refund.module').then( m => m.OrderRefundModule)
  },
  {
    path: 'privacy-policy',
    loadChildren: () => import('./privacy-policy/privacy-policy.module').then( m => m.PrivacyPolicyPageModule)
  },
  {
    path: 'profile-activation',
    loadChildren: () => import('./profile-activation/profile-activation.module').then( m => m.ProfileActivationPageModule)
  },
  {
    path: 'restaurant-quarters',
    loadChildren: () => import('./restaurant-quarters/restaurant-quarters.module').then( m => m.RestaurantQuartersPageModule)
  },
  {
    path: 'subscribe-courier',
    loadChildren: () => import('./subscribe-courier/subscribe-courier.module').then( m => m.SubscribeCourierPageModule)
  },
  {
    path: 'subscribe-restaurant',
    loadChildren: () => import('./subscribe-restaurant/subscribe-restaurant.module').then( m => m.SubscribeRestaurantPageModule)
  },
  {
    path: 'term-and-condition',
    loadChildren: () => import('./term-and-condition/term-and-condition.module').then( m => m.TermAndConditionPageModule)
  },
  {
    path: 'weekly-availability',
    loadChildren: () => import('./weekly-availability/weekly-availability.module').then( m => m.WeeklyAvailabilityPageModule)
  },
  {
    path: 'welcome-slides',
    loadChildren: () => import('./welcome-slides/welcome-slides.module').then( m => m.WelcomeSlidesPageModule)
  },
  {
    path: 'stripe-connect',
    loadChildren: () => import('./stripe-connect/stripe-connect.module').then( m => m.StripeConnectModule)
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
    RouterModule.forChild(routes),
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
