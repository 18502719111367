/*

	city.ts

	Provider for city

	Authors:	Matteo Carrara (team@flaash.it)

	History:	2018.04.12 - File created

*/
import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { CfgProvider } from './cfg';
import { SettingsProvider } from './settings';
import { HelpersProvider } from './helpers';
import { LogProvider } from './log';
import { ApiProvider } from './api';

@Injectable()
export class CityProvider {

  CITY_CONST={
    Bergamo:1,
    Brescia:2
  }

  cityID;
  citySelected;
  settingsData;
  info;
	info$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
		public cfg:CfgProvider,
		public log:LogProvider,
		public settings:SettingsProvider,
		public helpers:HelpersProvider,
		public api:ApiProvider
	){}


      // initialise provider
    ngUnsubscribe: Subject<void> = new Subject<void>();
    async init() {
      // subscribing for getting actual settings list
      this.settings.data$
        .pipe(takeUntil( this.ngUnsubscribe ))
        .subscribe((settings) => {
          this.settingsData = settings;

          // extracting cart info and sending to subscribers
				if( this.settingsData['citySelected'] ) {
					try {
						this.info = JSON.parse(this.settingsData['citySelected']);
						this.info$.next( this.info );
					} catch(e) {
						this.log.debug( 'failed to parse local city info', e.message||e );
					}
				}

        })
      ;
    }

   async getCitySelected() {
     //console.log('second', this.info);
		return this.info ;
	}

  async getCityId(){
    this.citySelected = await this.getCitySelected();
    if(this.citySelected == "Bergamo"){
      this.cityID = this.CITY_CONST.Bergamo;
    }

    return this.cityID;
  }

}
