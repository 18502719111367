/*

	cfg.ts

	Provider to work with config

	Authors:	Matteo Carrara (team@flaaash.it)

	History:	2018.04.06 - File created

*/
import { Injectable } from '@angular/core';
import { BehaviorSubject  } from 'rxjs';
import { LoadingController } from '@ionic/angular';
import { environment } from '../../environments/environment';
import { HelpersProvider } from './helpers';

@Injectable()
export class CfgProvider {

	courierMasterId = 4;
	restaurantMasterId = 2;

	// substract 10 minutes from quarter when showing to restaurant delivery time
	restaurantQuarterSub_min = 10;

	debug = true;
	initCompleted = false;

	url = {
		api: this.getApiUri(),

		placeholder: {
			productImage: 'assets/imgs/placeholder.png',
			restaurantLogo: 'assets/imgs/placeholder.png',
			restaurantCover: 'assets/imgs/placeholder.png'
		},
		tos: {
			common: 	'tos.html',
			courier:	'tos-courier.html',
			restaurant:	'tos-restaurant.html'
		}
	};

	getApiUri(): string {
		if (environment['API_URI'] && environment['API_URI'].length > 0) {
			return environment['API_URI'];
		}
		if (environment.production) {
			return 'https://server.flaaash.delivery/';
		}
		else {
			return 'https://server.flash-delivery.it/'; //url api for testing server
		}
	}

	userType = {
		customer: 1, // todo: use same names as backend
		USER_TYPE_CUSTOMER:	1,

		restaurant: 2,
		USER_TYPE_RESTAURANT_OWNER: 2,

		courier: 3,
		USER_TYPE_COURIER: 3,

		courierManager: 4,
		USER_TYPE_COURIERMANAGER: 4,
		
		USER_TYPE_RESTAURANT_MANAGER: 6,
	};

	txt = {
		orderStates: {
			'0': 'Non accettato',
			'1': 'Accettato',
			'2': 'Processato',
			'3': 'Visto dal Risorante',
			'4': 'In preprarazione',
			'5': 'In consegna',
			'6': 'Consegnato',
			'7': 'Rimborsato'
		}
	};

	timeout = {
		toast: 7000
	};

	citySelected = '';

	settingsPrefix = 'flashers';
	settings_rows = {
		// core settings
		welcomeSlidesShowed: '0',
		lastEmail: '',
		restaurantConfirmedTos: '0',
		courierConfirmedTos: '0',

		// server settings
		server: '',
		xAuthHeader: '',

		// user settings
		userType:'',
		userState: '',
		courierInfo: '',
		courierData: '',
		restInfo: '',
		restData: ''
	};

	strings = {
		openHours: {
			openHourLunch:		'Open hour lunch',
			closeHourLunch:		'Close hour lunch',
			openHourDinner:		'Open hour dinner',
			closeHourDinner:	'Close hour dinner'
		}
	};

	defaults = {
		pageLoginned: '/tabs/ordini'
	};

	productImageDimensions = {
		width: 300,
		height: 300
	};

	coverImageDimension = {
		width: 300,
		height: 200
	};

	restaurant = {
		waitingTimes: [ 20, 30, 40, 50, 60 ]
	};

	// this value will be assigned from app.component
	pagesMap;

	constructor(
		public helpers:HelpersProvider,
		public loadingCtrl:LoadingController
	) {
		this.helpers.cfg = this;
	}

	load;
	loading$: BehaviorSubject<any> = new BehaviorSubject<any>(false);
	async loading(loader){
		try {
			if( this.load ) {
				await this.load.dismiss();
			}

			if(loader) {
				this.load = this.loadingCtrl.create({
					message: "Caricamento..."
				});
				await this.load.present();
				this.loading$.next(true);
			} else {
				this.loading$.next(false);
			}
		} catch(e) {}
	}
}
