/*

	helpers.ts

	Helpers

	Authors:	Matteo Carrara (team@flaaash.it)

	History:	2018.05.04 - File created

*/
import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable()
export class HelpersProvider {
	cfg;

	isBrowser() {
		return document.URL.includes('https://') || document.URL.includes('http://');
	}

	// group quarters by hour
	groupQuarters(quarters) {
		let res = {};
		for (let i = 0; i < quarters.length; i++) {
			let quarter = quarters[i];
			// console.warn( quarter );
			try {
				// form required data
				let hour = this.fillZero(quarter.substr(0, quarter.indexOf(':')), 2);

				// save result
				if (typeof res[hour] == 'undefined') {
					res[hour] = new Array();
				}
				res[hour].push(quarter);
			} catch (e) { }
		}
		return res;
	}

	getUnixWithTZOffset(unix) {
		return unix + moment.unix(unix).utcOffset() * 60
	}

	createRange(number) {
		var items: number[] = [];
		for (var i = 1; i <= number; i++) {
			items.push(i);
		}
		return items;
	}

	base64UriAsData(dataURI) {
		var BASE64_MARKER = ';base64,';
		var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
		var base64 = dataURI.substring(base64Index);
		return base64;
	}

	isEmpty(item) {
		if (this.isNumeric(item)) {
			return item.length > 0;
		} else {
			for (var index in item) {
				if (item[index] != null) {
					return false;
				}
			}
			return true;
		}
	}

	isNumeric(n) {
		return !isNaN(parseFloat(n)) && isFinite(n);
	}

	isFine(item) {
		return typeof (item) != 'undefined' && item !== null && item !== '';
	}

	randomGet(str) {
		return str + '?' + Math.random().toString(36).substr(0, 5);
	}

	fillZero(str, zero_count, symbol = '0', right_join = false) {
		var zeros = '';
		if (typeof (str) != 'string') {
			str = str.toString();
		}

		if (str.length >= zero_count) {
			return str;
		}

		for (var i = str.length; i < zero_count; i++) {
			zeros += symbol;
		}

		if (right_join) {
			return str + zeros;
		} else {
			return zeros + str;
		}
	}

	getRestaurantPreparationTime(quarter) {
		let result = '';

		if (quarter) {
			let periods = this.parseQuarter(quarter);

			// patching periods values
			if (periods && periods.from && periods.to) {
				periods.from -= this.cfg.restaurantQuarterSub_min;
				periods.to = periods.from + this.cfg.restaurantQuarterSub_min;

				result = `${this.timeForMinutes(periods.from)} - ${this.timeForMinutes(periods.to)}`;
			}
		}

		return result;
	}

	parseQuarter(quarter) {
		let res = [0, 0];

		let timesList = quarter.replace(/\s/g, '').split('-');
		for (let i = 0; i < timesList.length; i++) {
			res[i] = this.parseMinutesAfterMidnight(timesList[i]);
		}

		return {
			from: res[0],
			to: res[1]
		};
	}

	// parse 24-h time to minutes after midnight
	parseMinutesAfterMidnight(timeRaw) {
		let time24 = moment(timeRaw, ["h:mm A"]).format("HH:mm");
		let [hours, minutes] = time24.replace(/[^0-9:]/g, '').split(':').map((val) => parseInt(val));
		return hours * 60 + minutes;
	}

	// represent minutes-after-midnight as clock-time
	timeForMinutes(minutes, with_sign = false) {
		var sign = '';
		if (minutes > 0) {
			var hours = Math.floor(minutes / 60);

			if (with_sign) {
				sign = '+';
			}
		} else {
			var hours = Math.ceil(minutes / 60);

			if (with_sign) {
				sign = '-';
			}
		}
		minutes = minutes - hours * 60;

		return sign + this.fillZero(Math.abs(hours), 2) + ':' + this.fillZero(Math.abs(minutes), 2);
	}

	basename(path, suffix = null) {
		//  discuss at: http://locutus.io/php/basename/
		// original by: Kevin van Zonneveld (http://kvz.io)
		// improved by: Ash Searle (http://hexmen.com/blog/)
		// improved by: Lincoln Ramsay
		// improved by: djmix
		// improved by: Dmitry Gorelenkov
		//   example 1: basename('/www/site/home.htm', '.htm')
		//   returns 1: 'home'
		//   example 2: basename('ecra.php?p=1')
		//   returns 2: 'ecra.php?p=1'
		//   example 3: basename('/some/path/')
		//   returns 3: 'path'
		//   example 4: basename('/some/path_ext.ext/','.ext')
		//   returns 4: 'path_ext'

		var b = path
		var lastChar = b.charAt(b.length - 1)

		if (lastChar === '/' || lastChar === '\\') {
			b = b.slice(0, -1)
		}

		b = b.replace(/^.*[/\\]/g, '')

		if (typeof suffix === 'string' && b.substr(b.length - suffix.length) === suffix) {
			b = b.substr(0, b.length - suffix.length)
		}

		return b
	}

	getExt(filename) {
		return filename.split('.').pop().toLowerCase();
	}
}
