import { Component } from '@angular/core';

import { Platform, ToastController, AlertController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// providers
import { ApiProvider } from './providers/api';
import { CfgProvider } from './providers/cfg';
import { HelpersProvider } from './providers/helpers';
import { RedirectionProvider } from './providers/redirection';
import { SettingsProvider } from './providers/settings';
import { ToastProvider } from './providers/toast';
import { LogProvider } from './providers/log';
import { CityProvider } from './providers/city';
import { UserProvider } from './providers/user';
import { RestaurantProvider } from './providers/restaurant';
import { CourierProvider } from './providers/courier';
import { RightsProvider } from './providers/rights';
import { PushProvider } from './providers/push';

@Component({
	selector: 'app-root',
	templateUrl: 'app.component.html',
	styleUrls: ['app.component.scss']
})
export class AppComponent {

	rootPage: any;

	userInfo = null;
	citySelected;

	constructor(
		public platform: Platform,
		public statusBar: StatusBar,
		public splashScreen: SplashScreen,
		public toastCtrl: ToastController,
		public alertCtrl: AlertController,
		public api: ApiProvider,
		public cfg: CfgProvider,
		public helpers: HelpersProvider,
		public redirection: RedirectionProvider,
		public settings: SettingsProvider,
		public toast: ToastProvider,
		public log: LogProvider,
		public city: CityProvider,
		public user: UserProvider,
		public restaurant: RestaurantProvider,
		public courier: CourierProvider,
		public rights: RightsProvider,
		public pushProvider: PushProvider
	) {
		this.initializeApp();
	}

	ngUnsubscribe: Subject<void> = new Subject<void>();
	async initializeApp() {
		// init our custom services
		await this.settings.init();
		await this.user.init();
		await this.api.init();
		await this.city.init();
		await this.restaurant.init();
		await this.courier.init();

		this.user.authState$
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(async (newAuthState) => {
				try {
					if (newAuthState !== null) {
						this.log.debug('user auth state', newAuthState.state);
						let redirectTo = 'login';
						let redirectParams = {};

						if (newAuthState.state) {

							// checking, that current user type confirmed ToS
							redirectTo = this.cfg.defaults.pageLoginned;

							if (newAuthState.info.user.isAgreementConfirmed == false) {
								redirectTo = 'term-and-condition';
							}

							// // form tos name if required for current user type
							// let tosType = '';
							// if( newAuthState.info.common.userType == this.cfg.userType.restaurant ) {
							// 	tosType = 'restaurant';
							// }
							// if( newAuthState.info.common.userType == this.cfg.userType.courier ) {
							// 	tosType = 'courier';
							// }
							//
							// // checking, that current user type confirmed ToS
							// redirectTo = this.cfg.defaults.pageLoginned;
							// if( tosType ) {
							// 	let tosSettingName = `${tosType}ConfirmedTos`;
							//
							//
							// 	let tosConfirmed = await this.settings.Get( tosSettingName );
							// 	if( tosConfirmed != '1' ) {
							// 		redirectTo = 'TermAndConditionPage';
							// 	}
							// }
						} else {
							// make redirection based on welcome-slides showed
							let welcomeSlidesShowed = await this.settings.Get('welcomeSlidesShowed');
							if (welcomeSlidesShowed != '1') {
								redirectTo = 'welcome-slides';
							}
						}
						console.log(redirectTo);
						this.redirection.setRoot(redirectTo, redirectParams);
					}
				} catch (e) {
					this.log.debug(e.message || e.error);
				}
			})
			;

		//getting user actual info
		this.user.info$
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(info => this.userInfo = info)
			;
		// // receiving toast command
		// this.toast.create$
		// 	.pipe(takeUntil( this.ngUnsubscribe ))
		// 	.subscribe((params) => {
		// 		if( params ) {
		// 			// default toast params
		// 			var opts = {
		// 				message:	'',
		// 				duration:	this.cfg.timeout.toast,
		// 				position:	'bottom'
		// 			};
		//
		// 			// process arguments
		// 			if( typeof params == 'string' ) {
		// 				opts.message = params;
		// 			} else {
		// 				opts = params;
		// 			}
		// 			// show toast
		// 			this.toastCtrl.create(opts).present();
		// 		}
		// 	})
		// ;
		// this.toast.alert$
		// 	.pipe(takeUntil( this.ngUnsubscribe ))
		// 	.subscribe((params) => {
		// 		if( params ) {
		// 			// default toast params
		// 			var opts = {
		//         header:'',
		// 				message:	'',
		// 				buttons:	['Ok']
		// 			};
		//
		// 			// process arguments
		// 			if( typeof params == 'string' ) {
		// 				opts.message = params;
		// 			} else {
		// 				opts = params;
		// 			}
		// 			// show toast
		// 			await this.alertCtrl.create(opts).present();
		// 		}
		// 	})
		// ;
		// receiving toast command
		this.toast.create$
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(async (params) => {
				if (params) {
					// default toast params
					var opts = {
						message: '',
						duration: this.cfg.timeout.toast
					};

					// process arguments
					if (typeof params == 'string') {
						opts.message = params;
					} else {
						opts = params;
					}
					// show toast
					const toaster = await this.toastCtrl.create(opts)
					toaster.present();
				}
			})
			;
		this.toast.alert$
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(async (params) => {
				if (params) {
					// default toast params
					var opts = {
						header: '',
						message: '',
						buttons: ['Ok']
					};

					// process arguments
					if (typeof params == 'string') {
						opts.message = params;
					} else {
						opts = params;
					}
					// show toast
					const toaster = await this.alertCtrl.create(opts);
					toaster.present();
				}
			})
			;

		this.cfg.initCompleted = true;
		await this.platform.ready();
		this.statusBar.styleDefault();
		this.splashScreen.hide();

		// init onesignal
		this.pushProvider.setupOneSignal();
	}
}
