/*

	keys.pipe.ts

	Get object's keys

	Authors:	Ilia Ashmarin (mail[0]harduino.com)

	History:	2018.06.27 - File created
*/
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'keys',
})
export class KeysPipe implements PipeTransform {
	transform( value, changeIndicator:number ) : any {
		return typeof value == 'object' && value ? Object.keys(value) : [];
	}
}
