/*

	tos.ts

	Work with TOS

	Authors:	Ilia Ashmarin (mail[0]harduino.com)

	History:	2018.05.11 - File created

*/
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { CfgProvider } from './cfg';
import { ToastProvider } from './toast';
import { ApiProvider } from './api';
import { SettingsProvider } from './settings';

@Injectable()
export class TosProvider {
	settingsData;

	constructor(
		public cfg:CfgProvider,
		public toast:ToastProvider,
		public api:ApiProvider,
		public settings:SettingsProvider
	) { }

	ngUnsubscribe: Subject<void> = new Subject<void>();
	async init() {
		// subscribing for getting actual settings list
		this.settings.data$
			.pipe(takeUntil( this.ngUnsubscribe ))
			.subscribe((settings) => {
				this.settingsData = settings;
			})
		;
	}

	async getHtml(uri = null) {
		uri = uri || this.cfg.url.tos.common;
		let response = await this.api.get( uri, {}, 'text' );
		if(response.status == 200 && !response.error) {
			return response.body;
		} else {
			throw response;
		}
	}
}
