/*

	courier.ts

	Provider to work with courier

	Authors:	Ilia Ashmarin (mail[0]harduino.com)

	History:	2018.06.29 - File created

*/
import { Injectable } from '@angular/core';

import { Subject, BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { CfgProvider } from './cfg';
import { HelpersProvider } from './helpers';
import { ToastProvider } from './toast';
import { ApiProvider } from './api';
import { UserProvider } from './user';

@Injectable()
export class CourierProvider {

	info = null;
	info$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

	constructor(
		public cfg:CfgProvider,
		public helpers:HelpersProvider,
		public toast:ToastProvider,
		public api:ApiProvider,
		public user:UserProvider
	) {}

	ngUnsubscribe: Subject<void> = new Subject<void>();
	async init() {
		this.user.info$
			.pipe(takeUntil( this.ngUnsubscribe ))
			.subscribe((info) => {
				if( info && info.user && !this.helpers.isEmpty(info.user) ) {
					this.info = info.user;
					this.info$.next( this.info );
				}
			})
		;
	}

	availability = null;
	availability$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
	async actualizeAvailability() {
		await this.api.actualize.call(
			this,
			{
				method:		'courier/availability/get',
				varName:	'availability'
			}
		);
	}
	async updateAvailability(newData) {
		await this.api.updateData.call(
			this,
			{
				method:		'courier/availability/set',
				data:		newData,
				varName:	'availability'
			}
		);
	}
}
